const ottoText = `%c
                                                      %c
                                           .-:        
                :-.       :-.        .*#+ :*##:  ..   
                ##*      :##+        .*#* :+*+.=*##-  
    .:-==-:.   .###----: :##*----:    :-===-:  =#*+   
  :*##*++*##+. .###****+ :##*****+  :*##*++*#*=  :--: 
 .*#*:    :##* .###      :##+      -##+     =##= *##+ 
 -##=      *##..###      :##+      =##-      ##* .:.  
 .*#*:    -##*  ###   :-..##*   :- -##+.    =##=      
  .+##*++*#*=   -*#*+*##- =##*+*#*: :*##*++*#*=       
    .:-==-:       :-=--.   .-==-:     .--==-:         
%c                                                      `;
const borderRadiusTop = 'border-radius: 40px 40px 0 0;';
const borderRadiusBottom = 'border-radius: 0 0 40px 40px;';

const fontWeight = 'font-weight: extrabold';
const backgroundColor = 'background-color: #e8e7fe;';
const textColor = 'color: #554ef6;';
export const logOtto = (): void => {
  console.log(
    ottoText,
    `${borderRadiusTop} ${fontWeight} ${textColor} ${backgroundColor}`,
    `${fontWeight} ${textColor} ${backgroundColor}`,
    `${borderRadiusBottom} ${fontWeight} ${textColor} ${backgroundColor}`,
  );
};
