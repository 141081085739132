export enum FeatureFlagKey {
  Care = 'Care',
  CarePlans = 'CarePlans',
  NpsSurvey = 'NpsSurvey',
  LoyaltyProgram = 'LoyaltyProgram',
  CarePlansPreEnrollment = 'CarePlansPreEnrollment',
  VideoConversionViaMediaJobs = 'VideoConversionViaMediaJobs',
  PetPortalUserTimezone = 'PetPortalUserTimezone',
  DisableDirectBookingConfirmation = 'DisableDirectBookingConfirmation',
  InAppReview = 'InAppReview',
  DisableCare2FA = 'DisableCare2FA',
  EnablePetIdForEzyvetClinic = 'EnablePetIdForEzyvetClinic',
  RequestsWidgetInPetPortal = 'RequestsWidgetInPetPortal',
  SurchargeUpdates = 'SurchargeUpdates',
}
